import request from '@/utils/request'


// 一级-停车记录-查询
export function parkinglog_get(data) {
    return request({
        url: '/platform_api/manage_api/parking_record/query',
        method: 'post',
        data: data
    })
}
//二级-停车记录-更新
export function parkinglog_update(data) {
    return request({
        url: '/platform_api/manage_api/parking_record/update',
        method: 'post',
        data: data
    })
}
// 二级-获取停车记录
export function parkinglogId_get(data) {
    return request({
        url: '/platform_api/manage_api/parking_record/get',
        method: 'post',
        data: data
    })
}